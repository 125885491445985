import { CursoComImagem, } from './models/curso-com-imagem';

import { AfterViewInit, Component,  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';

import { ListaBlog } from 'src/app/blog/models/listaBlog';
import { Instituicao } from 'src/app/instituicao/models/Instituicao';
import { Cursos } from './../../cursos/models/cursos';
import { Endereco } from './../../instituicao/models/endereco';

import { BlogService } from 'src/app/blog/services/blog.service';
import { CursosService } from './../../cursos/services/cursos.service';
import { InstituicaoService } from './../../instituicao/services/instituicao.service';
import { HomeService } from '../services/home.service';

import { environment } from 'src/environments/environment';

import { SessionStorageUtils } from 'src/app/utils/sessionStorage';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./../homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit {

  urlUi: string = environment.urlUi;
  imagens: string = environment.imagensUrl;
  imagensI: string = environment.imagensUrlI;
  imagensB: string = environment.imagensUrlBolsas;
  sessionStorageUtils = new SessionStorageUtils();

  listaBlogGraduacao: ListaBlog[];
  listaBlogInformacoes: ListaBlog[];
  listaBlogNovidades: ListaBlog[];
  listaBlogNoticias: ListaBlog[];
  cursos: Cursos[];

  cursosFiltro: Cursos[];
  cursosList: Cursos[];
  instituicoes: Instituicao[];
  enderecos: Endereco[];
  enderecoInstituicao: Endereco;
  cursoComImagem: CursoComImagem;
  value: any;
  active: any;
  isDragging: any;
  valueId = '1';
  buscaFac = 'opcao0';
  buscaPos = 'opcao0';
  buscaEsc = 'opcao0';
  buscaCur = 'opcao0';
  urlBlog: string;
  cursosComImagem: CursoComImagem[] = new Array<CursoComImagem>();

  //session items
  cursoNome: string;
  instituicaoNome: string;
  cidadeInstituicao: string;
  modalidadeId = 1;
  errorMessage: string;
  searchNoticias: string;
  searchInformacoes: string;
  searchNovidades: string;
  searchGraduacao: string;
  searchBolsas: string;
  searchTurno01: number = 1;
  searchCidade: string;
  formulario: FormGroup;

  buscaFac001: string;
  buscaPos001: string;
  buscaEsc001: string;
  buscaCur001: string;

  menorValorG: any;
  menorValorP: any;
  menorValorC: any;
  menorValorE: any;

  sub: Subscription;

  imgCurso: ListaBlog;
  nomeCursos: string;
  nomeInstituicoes: string;

  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      400: { items: 2 },
      740: { items: 3 },
      940: { items: 4 }
    },
    nav: false
  }

  noticiasOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 1 },
      940: { items: 4 }
    },
    nav: false
  }

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private blogService: BlogService,
    private cursosService: CursosService,
    private instituicaoService: InstituicaoService,
    private homeService: HomeService,
    private router: Router
  ) { }


  ngOnInit(): void {

    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.urlBlog = this.urlUi + "categoria/blog";

    this.formulario = this.fb.group({
      nomeCurso: [],
      instituicaoNome: [],
      cidadeFaculdade: [],
      cidadePos: [],
      cidadeEscola: [],
      cidadeCursosT: [],
      buscaFac001: [],
      buscaPos001: [],
      buscaEsc001: [],
      buscaCur001: [],
    });

    this.searchNoticias = "Notícias";
    this.searchInformacoes = "Informações";
    this.searchNovidades = "Novidades";
    this.searchGraduacao = "Graduação";

    this.instituicaoService.obterCidadesEnderecos()
      .subscribe(enderecos => {
        this.enderecos = enderecos, error => this.errorMessage;
        if (!enderecos) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 10000);
        }
      });

    this.cursosService.obterTodosComVagas().subscribe(cursos => {
      this.cursosFiltro = cursos;
    }, error => this.errorMessage, () => {
      this.cursosFiltro = this.cursosFiltro.sort((a, b) => a.valorDesconto - b.valorDesconto);
      for (let curso of this.cursosFiltro) {
        const cursoJaAdicionado = this.cursosComImagem.filter((x) => { x.curso.nome === curso.nome }).length > 0;
        if (cursoJaAdicionado) continue;
        const imagem = this.blogService.obterPorNome(curso.nome).subscribe(res => {
          let imagem = res?.imagem === undefined ? "default.jpg" : res.imagem;
          const cursoComImagem: CursoComImagem = {
            curso: curso,
            pathImagem: imagem
          };
          this.cursosComImagem.push(cursoComImagem);
        });
      }
    });

    //Menores Valores
    this.homeService.obterMenorValorFaculdade()
      .subscribe(menorValorG => this.menorValorG = menorValorG, error => this.errorMessage);

    this.homeService.obterMenorValorPos()
      .subscribe(menorValorP => this.menorValorP = menorValorP, error => this.errorMessage);

    this.homeService.obterMenorValorTecnico()
      .subscribe(menorValorC => this.menorValorC = menorValorC, error => this.errorMessage);

    this.homeService.obterMenorValorEscola()
      .subscribe(menorValorE => this.menorValorE = menorValorE, error => this.errorMessage);

  }

  ngAfterViewInit(): void {

    this.cursosService.obterCursosParcial()
      .subscribe(cursosList => { this.cursosList = cursosList, error => this.errorMessage; })

    this.blogService.obterBlogsCategoria(this.searchGraduacao)
      .subscribe(listaBlogGraduacao => {this.listaBlogGraduacao = listaBlogGraduacao, error => this.errorMessage;
      console.log(this.listaBlogGraduacao)});

    this.blogService.obterBlogsCategoria(this.searchNoticias)
      .subscribe(listaBlogNoticias => this.listaBlogNoticias = listaBlogNoticias, error => this.errorMessage);

    this.blogService.obterBlogsCategoria(this.searchInformacoes)
      .subscribe(listaBlogInformacoes => this.listaBlogInformacoes = listaBlogInformacoes, error => this.errorMessage);

    this.blogService.obterBlogsCategoria(this.searchNovidades)
      .subscribe(listaBlogNovidades => this.listaBlogNovidades = listaBlogNovidades, error => this.errorMessage);
  }

  imagemCurso(value) {
    this.blogService.obterPorNome(value)
      .subscribe(imgCurso => this.imgCurso = imgCurso)
  }

  getEnderecoId(value: string) {

    let nomeCidade = value

    this.homeService.obterNomeCursoPorModalidade(this.modalidadeId, nomeCidade)
      .subscribe(nomeCursos => { this.nomeCursos = nomeCursos, error => this.errorMessage; if (!nomeCursos) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 10000);
        }; console.log(this.nomeCursos )
      });

      this.homeService.obterNomeInstiuicaoPorModalidade(this.modalidadeId, nomeCidade)
      .subscribe(nomeInstituicoes => { this.nomeInstituicoes = nomeInstituicoes, error => this.errorMessage; if (!nomeInstituicoes) {
          this.spinner.show();
          setTimeout(() => {
            this.spinner.hide();
          }, 10000);
        };console.log(this.nomeInstituicoes)
      });

  }

  getCidade(value: string) {
    this.searchBolsas = value;
  }

  getModalidadeId(value: any) {
    this.instituicaoService.obterTodosEnderecos(value)
      .subscribe(enderecos => {
        this.enderecos = enderecos, error => this.errorMessage;
        if (!enderecos) {
          this.spinner.show();
        }
      });

    return this.valueId = value;

  }
  saveSession() {

    let cidade = this.formulario.value['cidadeFaculdade'];
    let instituicaoNome = this.formulario.value['instituicaoNome'];
    let nomeCurso = this.formulario.value['nomeCurso'];
    let modalidadeId = this.modalidadeId;
    let categoriaId = null;
    let turnoId = null;

    this.sessionStorageUtils.salvarBuscaCategoria(cidade, nomeCurso, instituicaoNome, modalidadeId, categoriaId, turnoId);

    this.router.navigate(['bolsas/categorias']);
  }

  saveModalidadeId(id: number) {
    this.sessionStorageUtils.salvarModalidadeIdLocal(id);

    this.router.navigate(['/bolsas/categorias/modalidade', id]);
  }

  saveCidadeCurso(cidade: string, nomeCurso: string) {
    this.sessionStorageUtils.salvarBuscaCategoria(cidade, nomeCurso, null, null, null, null);

    this.router.navigate(['bolsas/categorias']);
  }

  resetForm() {
    this.formulario.reset();
    this.buscaFac = 'opcao0';
    this.buscaPos = 'opcao0';
    this.buscaEsc = 'opcao0';
    this.buscaCur = 'opcao0';
  }

}
