import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash'; 

@Pipe({
  name: 'filtroCidadesBolsas',
  pure: false
})

export class filtroCidadesBolsasPipe implements PipeTransform {
    transform(value: any): any{
        if(value != undefined || value != null){
            return _.uniqBy(value, 'cidadeInstituicao');
        }
        return value;
    }
}