import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash'; 

@Pipe({
  name: 'filter',
  pure: false
})

export class filtroCategorasPipe implements PipeTransform {

  transform(items: any[], value: string): any[] {
    if (!items) return [];
    if (!value) return items;

    return items.filter(singleItem =>
        singleItem['name'].toLowerCase().includes(value.toLowerCase())
    );

}
}
    