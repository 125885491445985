import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListaBlog } from '../models/listaBlog';
import { BaseService } from "src/app/services/base.service";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BlogService extends BaseService {
  private blogUrl: string = '/blogs/';
  private blogCategUrl: string = '/blogs/FiltrarCategorias/';
  constructor(private http: HttpClient) { super() }
    
   
obterTodos(): Observable<ListaBlog[]> {
    return this.http
        .get<ListaBlog[]>(this.UrlService + this.blogUrl)
        .pipe(catchError(super.serviceError));
}

obterTodosBlogs(): Observable<ListaBlog[]> {
    return this.http
        .get<ListaBlog[]>(this.UrlService + this.blogUrl)
        .pipe(catchError(super.serviceError));
}


obterBlogsCategoria(categoriaNome: string): Observable<ListaBlog[]> {
    return this.http
        .get<ListaBlog[]>(this.UrlService + this.blogUrl + 'categoria/' + categoriaNome)
        .pipe(catchError(super.serviceError));
}

obterPorNome(nomeBlog: string): Observable<ListaBlog> {
    return this.http
        .get<ListaBlog>(this.UrlService + this.blogUrl + 'Blog/' + nomeBlog)
        .pipe(catchError(super.serviceError));
}

obterPorId(id: string): Observable<ListaBlog> {
    return this.http
        .get<ListaBlog>(this.UrlService + this.blogUrl + id)
        .pipe(catchError(super.serviceError));
}

novoBlog(listaBlog: ListaBlog): Observable<ListaBlog> {
    return this.http
        .post(this.UrlService + this.blogUrl  + 'SalvarBlog', listaBlog)
        .pipe(map(super.extractData),
              catchError(super.serviceError));
}

editarBlog(listaBlog: ListaBlog): Observable<ListaBlog> {
    return this.http
        .put(this.UrlService + this.blogUrl + listaBlog.id, listaBlog)
        .pipe(map(super.extractData),
              catchError(super.serviceError));
}

excluirBlog(id: string): Observable<ListaBlog> {
    return this.http
        .delete(this.UrlService + this.blogUrl + id)
        .pipe(map(super.extractData),
              catchError(super.serviceError));
}
}


 
  

