import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { Cursos } from "../models/cursos";
import { Estatisticas } from 'src/app/dashboard/admin/home-admin/models/estatisticas';
import { ListaCurso } from '../models/listacurso';
import { ReservaBolsas } from '../models/reserva-bolsas';
import { Instituicao } from './../../instituicao/models/Instituicao';
import { EditarCurso } from "../models/editar-curso";
import { Turno } from "../models/turno";
import { Categoria } from "../models/categoria";
import { Modalidade } from "../models/modalidade";
import { CursoLista } from "../models/curso-lista";

import { BaseService } from "src/app/services/base.service";


@Injectable()
export class CursosService extends BaseService {
    
    setGroupFilter$ = new Subject<Cursos>();
    getGroupFilter = this.setGroupFilter$.asObservable();
    
    private instituicaoUrl: string = '/cadastro/instituicao/';
    private cursosUrl: string = '/cadastro/cursos/';
    private categoriasCursoUrl: string = '/categoriasCurso/';
    private formMailUrl: string = '/ReservaBolsas/';
    private instituicaoDashboardUrl: string = '/dashboard/instituicao/';
  
    constructor(private http: HttpClient) { super() }
  
    obterTodos(): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl)
            .pipe(catchError(super.serviceError));
    }

    obterCursosParcial(): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl + 'cursos/home/')
            .pipe(catchError(super.serviceError));
    }

    obterCursosCategoria(modalidadeId: number, cidade: string, curso: string): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl + 'cursos/categoria/' + modalidadeId + '/' + cidade + '/' + curso)
            .pipe(catchError(super.serviceError));
    }

    obterInstituicaoCategoria(modalidadeId: number, cidade: string, instituicaoNome: string ): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl + 'instituicao/categoria/' + modalidadeId + '/' + cidade + '/' + instituicaoNome)
            .pipe(catchError(super.serviceError));
    }

    obterCursosModalidadeCidade(modalidadeId: number, cidade: string ): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl + 'modalidade/cidade/' + modalidadeId + '/' + cidade)
            .pipe(catchError(super.serviceError));
    }

    obterCursosCidadeNome(cidade: string, nomeCurso: string ): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl + 'cursos/cidade/' + cidade + '/' + nomeCurso)
            .pipe(catchError(super.serviceError));
    }

    obterCursosModalidade(modalidadeId: number ): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl + 'cursos/modalidade/' + modalidadeId)
            .pipe(catchError(super.serviceError));
    }

    obterTodosComVagas(): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl + 'cursoComVagas/')
            .pipe(catchError(super.serviceError));
    }

    obterCategorias(): Observable<Categoria> {
        return this.http
            .get<Categoria>(this.UrlService + this.categoriasCursoUrl + 'categorias')
            .pipe(catchError(super.serviceError));
    }

    obterTurnos(): Observable<Turno> {
        return this.http
            .get<Turno>(this.UrlService + this.categoriasCursoUrl + 'turnos')
            .pipe(catchError(super.serviceError));
    }

    obterModalidades(): Observable<Modalidade> {
        return this.http
            .get<Modalidade>(this.UrlService + this.categoriasCursoUrl + 'modalidades')
            .pipe(catchError(super.serviceError));
    }

    obterPorUsuario(): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.cursosUrl + 'user')
            .pipe(catchError(super.serviceError));
    }

    obterListaCursos(): Observable<ListaCurso[]> {
        return this.http
            .get<ListaCurso[]>(this.UrlService + this.cursosUrl + 'listacursos')
            .pipe(catchError(super.serviceError));
    }   

    obterPorId(id: string): Observable<Cursos> {
        return this.http
            .get<Cursos>(this.UrlService + this.cursosUrl + id)
            .pipe(catchError(super.serviceError));
    }

    ObterInscricaoCursoInstituicaoId(id: string): Observable<Cursos> {
        return this.http
            .get<Cursos>(this.UrlService + this.cursosUrl + 'inscricao/' + id)
            .pipe(catchError(super.serviceError));
    }

    novoCurso(cursos: Cursos): Observable<Cursos> {
        return this.http
            .post(this.UrlService + this.cursosUrl, cursos)
            .pipe(map(super.extractData),
                  catchError(super.serviceError));
    }

    cadastroReservaBolsas(reservaBolsas: ReservaBolsas): Observable<ReservaBolsas> {
        return this.http
            .post(this.UrlService + this.formMailUrl + 'enviar/', reservaBolsas)
            .pipe(map(super.extractData),
                  catchError(super.serviceError));
    }

    novoCursoAdmin(cursos: Cursos): Observable<Cursos> {
        return this.http
            .post(this.UrlService + this.cursosUrl + 'admin', cursos)
            .pipe(map(super.extractData),
                  catchError(super.serviceError));
    }

    novoCursoListaAdmin(cursoLista: CursoLista): Observable<CursoLista> {
        return this.http
          .post(this.UrlService + this.categoriasCursoUrl + 'nomeCurso', cursoLista)
          .pipe(map(super.extractData),
            catchError(super.serviceError));
      }

    editarCurso(editarCurso: EditarCurso): Observable<Cursos> {
        return this.http
            .put(this.UrlService + this.cursosUrl + editarCurso.id, editarCurso)
            .pipe(map(super.extractData),
                  catchError(super.serviceError));
    }

    desativarAtivarCurso(cursos: Cursos): Observable<Cursos> {
        return this.http
            .put(this.UrlService + this.cursosUrl + 'desativarAtivarCurso/'+ cursos.id, cursos)
            .pipe(map(super.extractData),
                  catchError(super.serviceError));
    }    

    excluirCurso(id: string): Observable<Cursos> {
        return this.http
            .delete(this.UrlService + this.cursosUrl + id)
            .pipe(map(super.extractData),
                  catchError(super.serviceError));
    }    

    obterInstituicao(): Observable<Instituicao> {
        return this.http
            .get<Instituicao>(this.UrlService + this.instituicaoUrl + 'user')
            .pipe(catchError(super.serviceError));
    }

    obterEstatisticasInstituicao(): Observable<Estatisticas> {
        return this.http
          .get<Estatisticas>(this.UrlService + this.instituicaoDashboardUrl + 'estatisticas')
          .pipe(catchError(super.serviceError));
      }
}