import { Router } from '@angular/router';
import { Component, HostListener, Input, OnChanges } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { CursosService } from '../../services/cursos.service';
import { FiltrosService } from '../../services/filtros.service';

import { Cursos } from '../../models/cursos';
import { CursosDuracao } from '../../models/curso-duracao';

import { SessionStorageUtils } from './../../../utils/sessionStorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categoria-cursos',
  templateUrl: './categoria-cursos.component.html',
  styleUrls: ['../navegacao-cursos.scss']
})
export class CategoriaCursosComponent implements OnChanges {

  @HostListener('window:resize', ['$event'])
  @Input() groupFilters: Object;
  @Input() searchByKeyword: string;
  sessionStorageUtils = new SessionStorageUtils();
  cursos: Cursos[];
  novoCurso: CursosDuracao[] = new Array<CursosDuracao>();
  filteredCursos: any[] = [];
  errorMessage: string;
  imagensI: string = environment.imagensUrlI;
  percentual: any;
  cidade: any;
  nomeCurso: any;
  instituicaoNome: any;
  modalidadeId: any;
  naoEncontrado = false;
  cidadeUrl: any;
  route: any;
  nomeCursoUrl: any;
  duracaoA: any;
  duracaoM: any;
  orderBy = ' ';  
  p: number = 1;

  innerWidth: any;

  constructor(
    private cursosService: CursosService,
    private filtrosService: FiltrosService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.innerWidth = window.innerWidth; 

    this.cidade = this.sessionStorageUtils.obterCidade();
    this.nomeCurso = this.sessionStorageUtils.obterCurso();
    this.instituicaoNome = this.sessionStorageUtils.obterinstituicaoNome();
    this.modalidadeId = this.sessionStorageUtils.obterModalidade_Id();
    

    if (this.modalidadeId != null && this.cidade != null && this.nomeCurso != null) {
      this.cursosService.obterCursosCategoria(this.modalidadeId, this.cidade, this.nomeCurso)
        .subscribe(cursos => { this.cursos = cursos;
          this.filteredCursos = this.filteredCursos.length > 0 ? this.filteredCursos : this.cursos;
          
        if (this.cursos.length <= 0){
          this.naoEncontrado = true;
        }
        });
    }

    else if (this.modalidadeId != null && this.cidade != null && this.instituicaoNome != null) {
      this.cursosService.obterInstituicaoCategoria(this.modalidadeId, this.cidade, this.instituicaoNome)
        .subscribe(cursos => { this.cursos = cursos;
          this.filteredCursos = this.filteredCursos.length > 0 ? this.filteredCursos : this.cursos;
          
        if (this.cursos.length <= 0){
          this.naoEncontrado = true;
        }
        });
    }

    else if (this.modalidadeId != null && this.cidade != null && this.nomeCurso == null && this.instituicaoNome == null ) {
      this.cursosService.obterCursosModalidadeCidade(this.modalidadeId, this.cidade)
        .subscribe(cursos => { this.cursos = cursos;
          this.filteredCursos = this.filteredCursos.length > 0 ? this.filteredCursos : this.cursos;

        if (this.cursos.length <= 0){
          this.naoEncontrado = true;
        }
        });
    }

    else if (this.modalidadeId != null && this.cidade == null && this.nomeCurso == null && this.instituicaoNome == null ) {
      this.cursosService.obterCursosModalidade(this.modalidadeId)
        .subscribe(cursos => { this.cursos = cursos;
          this.filteredCursos = this.filteredCursos.length > 0 ? this.filteredCursos : this.cursos;
          
        if (this.cursos.length <= 0){
          this.naoEncontrado = true;
        }
        });
    }

    else if (this.modalidadeId == null && this.cidade != null && this.nomeCurso != null && this.instituicaoNome == null ) {
      this.cursosService.obterCursosCidadeNome(this.cidade, this.nomeCurso)
        .subscribe(cursos => { this.cursos = cursos;
          this.filteredCursos = this.filteredCursos.length > 0 ? this.filteredCursos : this.cursos;
          
        if (this.cursos.length <= 0){
          this.naoEncontrado = true;
        }
        });
    }

    else {
      this.cursosService.obterTodos().subscribe(cursos => { this.cursos = cursos;
        this.filteredCursos = this.filteredCursos.length > 0 ? this.filteredCursos : this.cursos;
        console.log(this.cursos)
      });
    }

    this.sessionStorageUtils.limparSessionFiltros();

  }   


  ngOnChanges() {
    if (this.groupFilters) this.filterCursosList(this.groupFilters, this.cursos);
  }

  filterCursosList(filters: any, cursos: Cursos[]) {

    Object.keys(filters).forEach(key => filters[key] === null && delete filters[key]);
    let keys = Object.keys(filters);
    let filterCursos = (curso: { [x: string]: any; }) => keys.every(key => curso[key] === filters[key]);

    let result = this.cursos.filter(filterCursos);

    if (result.length > 0) {
      this.naoEncontrado = false;
      this.filteredCursos = result;
      this.loadCursos();
    }

    if (result.length == 0) {
      this.naoEncontrado = true;
      this.filteredCursos = result;
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
    }
    this.p = 1;
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  loadCursos() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    this.sessionStorageUtils.limparSessionFiltros();
    this.cursosService.obterTodos().subscribe(cursos => {
      this.cursos = cursos;
      this.filteredCursos = this.filteredCursos.length > 0 ? this.filteredCursos : this.cursos;
    });
  }  

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

}
