
//IIS Server Padrão
export const environment = {    
  production: false,
  imagensUrl: 'https://bolsasagora.com.br/assets/img/blog/',
  imagensUrlBolsas: 'https://bolsasagora.com.br/assets/img/cursos/',
  imagensUrlI: 'https://bolsasagora.com.br/assets/img/logo-instituicao/',
  urlUi: 'https://bolsasagora.com.br/',
  urlAPI: 'https://bolsasagora.com.br/api/'
};



//LOCALSTORAGE(API Local)
// export const environment = {   
//   production: false,
//   imagensUrl: 'http://localhost:60104/assets/img/blog/',
//   imagensUrlBolsas: 'http://localhost:60104/assets/img/cursos/',
//   imagensUrlI: 'http://localhost:60104/assets/img/logo-instituicao/',
//   urlUi: 'http://localhost:4200/',
//   urlAPI: 'https://localhost:5001/'
// };





