export class SessionStorageUtils {

    //#region Inscricao
    public salvarInscricaoLocal(response: any) {
        this.salvarInscricao(response)
    }

    public salvarInscricao(value: string) {
        sessionStorage.setItem('inscricao', value);
    }

    public obterInscricaoId(): string {
        return sessionStorage.getItem('inscricao');
    }

    public limparSessionStorage() {
        sessionStorage.removeItem('inscricao');
    }
    //#endregion   

    //#region Modalidade
    public salvarModalidadeIdLocal(response: any) {
        this.salvarModalidade(response)
    }

    public salvarModalidade(value: string) {
        sessionStorage.setItem('modalidadeId', value);
    }

    public obterModalidadeId(): string {
        return sessionStorage.getItem('modalidadeId');
    }

    public limparModalidadeSession() {
        sessionStorage.removeItem('modalidadeId');
    }
    //#endregion 

    //#region FiltrosHome
    public salvarBuscaCategoria(cidade: string, curso: string, instituicaoNome: string, modalidadeId: number, categoriaId: number, turnoId: number) {
        this.salvarBuscaSessao(cidade, curso, instituicaoNome, modalidadeId );    }  

    public salvarBuscaSessao(cidade, curso, instituicaoNome, modalidadeId) {

        if (cidade != null ) { sessionStorage.setItem('cidadeSession', cidade); }
        if (curso != null ) { sessionStorage.setItem('cursoSession', curso); }
        if (instituicaoNome != null ) { sessionStorage.setItem('instituicaoNomeSession', instituicaoNome); }
        if (modalidadeId != null ) { sessionStorage.setItem('modalidadeIdSession', modalidadeId); }
    }

    public obterCidade(): string { return sessionStorage.getItem('cidadeSession'); }
    public obterCurso(): string { return sessionStorage.getItem('cursoSession'); }
    public obterinstituicaoNome(): string { return sessionStorage.getItem('instituicaoNomeSession'); }
    public obterModalidade_Id(): string { return sessionStorage.getItem('modalidadeIdSession'); }

    public limparSessionFiltros() {
        sessionStorage.removeItem('cidadeSession');
        sessionStorage.removeItem('cursoSession');
        sessionStorage.removeItem('instituicaoNomeSession');
        sessionStorage.removeItem('modalidadeIdSession');
    }
    //#endregion    

}
