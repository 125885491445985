import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { BaseService } from "src/app/services/base.service";
import { Cursos } from "../models/cursos";

@Injectable({providedIn: 'root' })
export class FiltrosService extends BaseService {

    constructor(private http: HttpClient) { super() }

    private filtrosUrl:string = '/filtros/'

    obterPorCursosEndereco(cidade: string, nomeCurso: string): Observable<Cursos[]> {      
        return this.http
            .get<Cursos[]>(this.UrlService + this.filtrosUrl + 'endereco/' + cidade + '/' + nomeCurso)
            .pipe(catchError(super.serviceError));
    }

    obterPorCursosCidade(cidade: string): Observable<Cursos[]> {      
        return this.http
            .get<Cursos[]>(this.UrlService + this.filtrosUrl + 'cidade/' + cidade)
            .pipe(catchError(super.serviceError));
    }

    obterPorCursosPorNome(nomeCurso: string): Observable<Cursos[]> {      
        return this.http
            .get<Cursos[]>(this.UrlService + this.filtrosUrl + 'curso/' + nomeCurso)
            .pipe(catchError(super.serviceError));
    }

    obterCursosPorInstituicaoId(id: string): Observable<Cursos[]> {      
        return this.http
            .get<Cursos[]>(this.UrlService + this.filtrosUrl + 'endereco/' + id)
            .pipe(catchError(super.serviceError));
    }

    obterPorModalidade(id: string): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.filtrosUrl + 'modalidade/' + id)
            .pipe(catchError(super.serviceError));
    }

    obterCursoPorId(id: string): Observable<Cursos> {
        return this.http
            .get<Cursos>(this.UrlService + this.filtrosUrl + 'bolsas/' + id)
            .pipe(catchError(super.serviceError));
    }

}