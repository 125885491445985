import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';

import { SideBarComponent } from './side-bar/side-bar.component';
import { TopBarBlogComponent } from './top-bar/top-bar.component';
import { CategoriaCursosComponent } from 'src/app/cursos/navegacao/categoria-cursos/categoria-cursos.component';

import { CursosService } from 'src/app/cursos/services/cursos.service';
import { InstituicaoService } from './../../../instituicao/services/instituicao.service';

import { filtroCategorasPipe } from '../pipes/filtroCategorias.bolsas.pipe';
import { filtroCidadesBolsasPipe } from '../pipes/filtroCidades.bolsas.pipe';
import { filtroCursosBolsasPipe } from '../pipes/filtroCursos.bolsas.pipe';
import { filtroInstituicaoBolsasPipe } from './../pipes/fitroInstituicaoBolsas.pipe';
import { OrderBy } from './../../../cursos/pipes/order-by.pipe';



@NgModule({
  
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    NgbModule,    
    ReactiveFormsModule,
    MatIconModule,
    NgxSpinnerModule,
    AngularStickyThingsModule,
    NgSelectModule,    
    NgxPaginationModule
    
  ],
  declarations: [    
    SideBarComponent,
    TopBarBlogComponent,
    CategoriaCursosComponent,
    filtroCategorasPipe,
    filtroCidadesBolsasPipe,
    filtroCursosBolsasPipe,
    filtroInstituicaoBolsasPipe,
    OrderBy

  ],
  providers:[
    CursosService,
    InstituicaoService
    
  ],
  exports: [
    SideBarComponent,
    TopBarBlogComponent,
    CategoriaCursosComponent,
  ]
})
export class ConponentsBolsasModule { }
