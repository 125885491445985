import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageUtils } from 'src/app/utils/localStorage';

declare interface RouteInfo {
  id?: any;
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const Usuarios: RouteInfo[] = [
  { path: '/inscricao/minhas-inscricoes', title: 'Painel', icon: 'ni-settings-gear-65', class: '' },
  { path: '/perfil-de-usuario', title: 'Perfil', icon: 'ni ni-single-02 text-primary', class: '' }  
];

export const InstituicaoRoutes: RouteInfo[] = [
  { path: '/dashboard-instituicao', title: 'Painel', icon: 'ni-settings-gear-65 text-primary', class: '' },
  { path: '/perfil-de-usuario', title: 'Perfil', icon: 'ni ni-single-02 text-primary', class: '' }
];

export const InstituicaoIniRoutes: RouteInfo[] = [
  { path: '/instituicao/adicionar-novo', title: 'Painel', icon: 'ni-settings-gear-65 text-primary', class: '' }
];

export const AdminRoutes: RouteInfo[] = [
  { path: '/dashboard-admin', title: 'Painel', icon: 'ni-settings-gear-65 text-primary', class: '' },
  { path: '/perfil-de-usuario', title: 'Perfil', icon: 'ni ni-single-02 text-primary', class: '' }
];

export const Anonimous: RouteInfo[] = [];

@Component({
  selector: 'app-header-h',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {

  [x: string]: any;
  token: string = "";
  user: any;
  localStorageUtils = new LocalStorageUtils();

  public menuItems: any[];
  public menuItemsMobs: any[];
  public isCollapsed = true;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.user = this.localStorageUtils.obterUsuario();

    if (this.user != null || this.user != undefined) {
      let claim = this.user.claims[0];

      if (claim.type === 'Instituicao' && claim.value === 'Add,Edit,Update,Delete,Visualize') {
        this.menuItemsMobs = InstituicaoRoutes.filter(menuItem => menuItem);
      }

      if (claim.type === 'Instituicao' && claim.value === 'Add,Visualize') {
        this.menuItemsMobs = InstituicaoIniRoutes.filter(menuItem => menuItem);
      }

      if (claim.type === 'Usuario' && claim.value === 'Add,Delete,Visualize') {
        this.menuItemsMobs = Usuarios.filter(menuItem => menuItem);
      }

      if (claim.type === 'WiziSuperAdmin' && claim.value === 'Add,Edit,Update,Delete,Visualize') {
        this.menuItemsMobs = AdminRoutes.filter(menuItem => menuItem);
      }

      if (claim.type === 'Instituicao' && claim.value === 'Add,Edit,Update,Delete,Visualize') {
        this.menuItems = InstituicaoRoutes.filter(menuItem => menuItem);
      }

      if (claim.type === 'Instituicao' && claim.value === 'Add,Visualize') {
        this.menuItems = InstituicaoIniRoutes.filter(menuItem => menuItem);
      }

      if (claim.type === 'Usuario' && claim.value === 'Add,Delete,Visualize') {
        this.menuItems = Usuarios.filter(menuItem => menuItem);
      }

      if (claim.type === 'WiziSuperAdmin' && claim.value === 'Add,Edit,Update,Delete,Visualize') {
        this.menuItems = AdminRoutes.filter(menuItem => menuItem);
      }
    }
  }

  userLogado(): boolean {

    this.token = this.localStorageUtils.obterTokenUsuario();
    this.user = this.localStorageUtils.obterUsuario();

    if (this.user) this.saudacao = this.user.nomeUsuario;

    return this.token !== null;

  }

  logOut() {
    this.localStorageUtils.limparDadosLocaisUsuario();
    this.router.navigate(['/home']);
  }
}