import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bolsas-layout-filtros',
  templateUrl: './bolsas-layout-filtros.component.html',
  styleUrls: ['./bolsas-layout.component.scss']
})
export class BolsasLayoutFiltrosComponent {
  
  searchText: string;
  filters: Object;

  constructor() { }

}
