import { Cursos } from './../../cursos/models/cursos';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { BaseService } from "src/app/services/base.service";
import { Instituicao } from 'src/app/instituicao/models/Instituicao';

@Injectable()
export class HomeService extends BaseService {   
    private homeUrl: string = '/home/';

    constructor(private http: HttpClient) { super() }

    obterInstituicoesPorCidade(cidade: string): Observable<Instituicao[]> {
        return this.http
            .get<Instituicao[]>(this.UrlService + this.homeUrl + 'instituicao/' + cidade)
            .pipe(catchError(super.serviceError));
    };

    obterCursosPorCidade(id: string): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.homeUrl + 'cursos/' + id)
            .pipe(catchError(super.serviceError));
    };
    
    obterCursosPorNomeCidade(nome: string): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.homeUrl + 'cursos/' + nome)
            .pipe(catchError(super.serviceError));
    };

    obterNomeCursoPorModalidade(modalidadeId: number, nomeCidade: string): Observable<string>
    {
        return this.http
        .get<string>(this.UrlService + this.homeUrl + 'nome/cursos/modalidade/' + modalidadeId + '/' + nomeCidade) 
        .pipe(catchError(super.serviceError));
    }

    obterNomeInstiuicaoPorModalidade(modalidadeId: number, nomeCidade: string): Observable<string>
    {
        return this.http
        .get<string>(this.UrlService + this.homeUrl + 'nome/instituicao/modalidade/' + modalidadeId + '/' + nomeCidade) 
        .pipe(catchError(super.serviceError));
    }

    obterCursosPorCidadeModalidade(id: number, cidade: string): Observable<Cursos[]> {
        return this.http
            .get<Cursos[]>(this.UrlService + this.homeUrl + 'cursos/modalidade/' + id + '/' + cidade)
            .pipe(catchError(super.serviceError));
    };

    obterInstituicoesPorModalidade(id: string, cidade: string): Observable<Instituicao[]>
    {
        return this.http
        .get<Instituicao[]>(this.UrlService + this.homeUrl + 'instituicao/modalidade/' + id + '/' + cidade) 
        .pipe(catchError(super.serviceError));
    }


    obterMenorValorFaculdade(): Observable<any>
    {
        return this.http
        .get<any>(this.UrlService + this.homeUrl + 'menorvalorf/' + 1)
        .pipe(catchError(super.serviceError));
    }

    obterMenorValorPos(): Observable<any>
    {
        return this.http
        .get<any>(this.UrlService + this.homeUrl + 'menorvalorp/' + 2)
        .pipe(catchError(super.serviceError));
    }

    obterMenorValorTecnico(): Observable<any>
    {
        return this.http
        .get<any>(this.UrlService + this.homeUrl + 'menorvalorct/' + 3)
        .pipe(catchError(super.serviceError));
    }

    obterMenorValorEscola(): Observable<any>
    {
        return this.http
        .get<any>(this.UrlService + this.homeUrl + 'menorvalore/' + 4)
        .pipe(catchError(super.serviceError));
    }
    
}