import { environment } from './../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { MatDialog } from '@angular/material/dialog';

import { AuthService } from './conta/services/auth.service';

import { PopUpBolsasComponent } from './home/pop-up-bolsas/pop-up-bolsas.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  public uiUrl: string = environment.urlUi;
  public title = 'Bolsas Agora - Cursos com até 50% de descontos para faculdades - Graduação e Pós-Graduação.';

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private titleService: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {

    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#303030"
        },
        button: {          
          background: "#3dab3a",
          text: "#ffffff"
        }
      },
      theme: "classic",
      content: {
        message: "Usamos cookies para melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa ",
        dismiss: "Concordo e fechar",
        link: "Política de Privacidade",
        href: this.uiUrl + "politica-de-privacidade/."
      }
    });

    this.titleService.setTitle(this.title);
    this.meta.addTag({ name: '', content: '' });
    this.meta.updateTag({ name: 'Keywords', content: 'educação, desconto universitario, bolsa de estudo, graduação, pós-graduação, curso superior, faculdade, universidade, curso tecnico com desconto, centro universitário, prouni, enem, cursos, vestibular, processo seletivo' });
    this.meta.updateTag({ name: 'description', content: 'O Bolsas Agora tem Bolsas de Estudo com até 50% de desconto em Faculdades de todo Brasil. Graduação e Pós-Graduação. Curso Técnico e Preparatório.' });

    this.authService.TokenExpired();

    setTimeout(() => {
      this.dialog.open(PopUpBolsasComponent);
    }, 5000);
    setTimeout(() => {
      this.dialog.closeAll();
    }, 15000);



  }
}
