import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nao-encontrado',
  templateUrl: './nao-encontrado.component.html'
})
export class NaoEncontradoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
