import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import localePt from '@angular/common/locales/pt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';
import { CustomFormsModule } from 'ng2-validation';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgBrazil } from 'ng-brazil';
import { JwtModule } from '@auth0/angular-jwt';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';



import { ErrorInterceptor } from './services/error.handle.service';
import { AuthService } from './conta/services/auth.service';

import { AdminLayoutComponent } from './navegacao/navegacao-dashboard/layout/admin-layout.component';
import { AuthLayoutComponent } from './navegacao/navegacao-conta/layout/auth-layout.component';
import { BolsasLayoutComponent } from './navegacao/navegacao-bolsas/layout/bolsas-layout.component';
import { WebsiteLayoutComponent } from './navegacao/navegacao-home/layout/website-layout.component';
import { AcessoNegadoComponent } from './dashboard/erros/acesso-negado/acesso-negado.component';
import { NaoEncontradoComponent } from './dashboard/erros/nao-encontrado/nao-encontrado.component';
import { ComponentsModule } from './navegacao/navegacao-dashboard/components/components.module';
import { ComponentsWebsiteModule } from './navegacao/navegacao-home/components/components-website.module';
import { ConponentsBolsasModule } from './navegacao/navegacao-bolsas/components/conponents-bolsas.module';
import { BolsasLayoutFiltrosComponent } from './navegacao/navegacao-bolsas/layout/bolsas-layout-filtros.component';
import { PoliticaPrivacidadeComponent } from './home/politica-privacidade/politica-privacidade.component';
import { TermosCondicoesComponent } from './home/termos-condicoes/termos-condicoes.component';
import { ConfirmModalComponent } from './shared/confirm-modal/confirm-modal.component';

import { environment } from 'src/environments/environment';
import { PopUpBolsasComponent } from './home/pop-up-bolsas/pop-up-bolsas.component';


export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];
export function tokenGetter() {
  return localStorage.getItem('app.token');
}

registerLocaleData(localePt, 'pt');

@NgModule({
  imports: [

    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ImageCropperModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatDialogModule,
    AlertModule,
    NgBrazil,
    ModalModule.forRoot(),    
    ComponentsWebsiteModule,
    ConponentsBolsasModule,
    ComponentsModule,
    CustomFormsModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      preventDuplicates: true,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.urlAPI],
        disallowedRoutes: []
      }
    }),
  ],

  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    WebsiteLayoutComponent,
    BolsasLayoutComponent,
    BolsasLayoutFiltrosComponent,
    AcessoNegadoComponent,
    NaoEncontradoComponent,
    PoliticaPrivacidadeComponent,
    TermosCondicoesComponent,
    ConfirmModalComponent,
    PopUpBolsasComponent
  ],

  entryComponents: [
    ConfirmModalComponent
  ],
  
  providers: [
    AuthService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    httpInterceptorProviders, 
    { provide: APP_BASE_HREF, useValue: '/' },
    MatDialog,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

