import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageUtils } from 'src/app/utils/localStorage';

declare interface RouteInfo {
  id?:any;
  path: string;
  title: string;
  icon: string;
  class: string;
}
declare interface BlogTitle {
  title: string;
}

export const Usuarios: RouteInfo[] = [  
  { path: '/inscricao/minhas-inscricoes', title: 'Painel', icon: 'ni-single-copy-04 text-primary', class: '' }, 
];

export const InstituicaoRoutes: RouteInfo[] = [
  { path: '/dashboard-instituicao', title: 'Home', icon: 'ni-tv-2 text-primary', class: '' }, 
  { path: '/instituicao/minha-instituicao', title: 'Instituição', icon: 'ni-building text-primary', class: '' },
  { path: '/cursos/meus-cursos', title: 'Cursos', icon: 'ni-paper-diploma text-primary', class: '' },
 
];

export const InstituicaoRoutesInscricaoes: RouteInfo[] = [
  { path: '/inscricao/lista-inscritos-semestre', title: 'Inscritos Semestre', icon: 'ni-book-bookmark text-primary', class: '' }, 
  { path: '/inscricao/lista-inscritos', title: 'Todos Inscritos', icon: 'ni-single-copy-04 text-primary', class: '' }, 
 
];

export const InstituicaoRoutesUser: RouteInfo[] = [
  { path: '/usuario/usuarios-intesse-bolsas', title: 'Lista Usuários', icon: 'fas fa-address-book text-primary', class: '' },  
 
];

export const InstituicaoAddRoute: RouteInfo[] = [
  { path: '/instituicao/adicionar-novo', title: 'Instituição', icon: 'ni-building text-primary', class: '' },  
];

export const AdminRoutes: RouteInfo[] = [
  { path: '/dashboard-admin', title: 'Home', icon: 'ni-tv-2 text-primary', class: '' },  
  { path: '/inscricao/lista-inscricoes', title: 'Inscrições', icon: 'ni-single-copy-04 text-primary', class: '' }, 
];

export const AdminRoutesInst: RouteInfo[] = [
  { path: '/instituicao/lista-todos', title: 'Instituições', icon: 'ni-building text-primary', class: '' },
  { path: '/instituicao/usuario/lista-usuario-instituicao', title: 'Lista Usuários', icon: 'ni-bullet-list-67 text-primary', class: '' }, 
  { path: '/instituicao/usuario/adicionar-novo-usuario-instituicao', title: 'Novo Usuario', icon: 'fa fa-user-plus text-primary', class: '' }, 
];

export const AdminRoutesUser: RouteInfo[] = [
  { path: '/usuario/lista-usuarios-cadastrados', title: 'Lista Usuários', icon: 'ni-bullet-list-67 text-primary', class: '' }, 
  { path: '/usuario/lista-usuarios-intesse-bolsas', title: 'Interesse Bolsas', icon: 'fas fa-address-book text-primary', class: '' },  
];
export const AdminRoutesCursos: RouteInfo[] = [
  { path: '/cursos/lista-todos-cursos', title: 'Cursos', icon: 'ni-paper-diploma text-primary', class: '' },
 
];

export const AdminRoutesBlog: RouteInfo[] = [  
  { path: '/blog/novo-blog', title: 'Novo Blog', icon: 'fa fa-folder-plus text-primary', class: '' },  
  { path: '/blog/lista-blog', title: 'Lista de Blog', icon: 'ni-bullet-list-67 text-primary', class: '' },   
];

export const TitBlog: BlogTitle[] = [{title: 'Blog'}]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['../../layout/admin-layout.component.scss']
})
export class SidebarComponent implements OnInit {  
  [x: string]: any;

  token: string = "";
  user: any;
  localStorageUtils = new LocalStorageUtils();
  
  public menuItems: any[];
  public menuItemsUser: any[];
  public menuItemsInscricoes: any[];
  public menuItemsInterresse: any[];
  public menuItemsInst: any[];
  public menuItemsBlog: any[];  public menuItensCurso: any[];
  public titsBlog: any[];
  public isCollapsed = true; 

  constructor(private route: ActivatedRoute, private router: Router)   {  }

  ngOnInit() {
    
    this.user = this.localStorageUtils.obterUsuario();
    let claim = this.user.claims[0];   

    if (claim.type === 'Instituicao'  && claim.value === 'Add,Visualize' ) {
      this.menuItems = InstituicaoAddRoute.filter(menuItem => menuItem);
    }

    if (claim.type === 'Instituicao' && claim.value === 'Add,Edit,Update,Delete,Visualize') {
      this.menuItems = InstituicaoRoutes.filter(menuItem => menuItem);      
      this.menuItemsInscricoes = InstituicaoRoutesInscricaoes.filter(menuItemsInscricoes => menuItemsInscricoes);
      this.menuItemsInterresse = InstituicaoRoutesUser.filter(menuItemInterresse => menuItemInterresse);
    }    

    if(claim.type === 'Usuario'  && claim.value === 'Add,Delete,Visualize'){
      this.menuItems = Usuarios.filter(menuItem => menuItem);
    }

    if (claim.type === 'WiziSuperAdmin' && claim.value === 'Add,Edit,Update,Delete,Visualize') {
      this.menuItems = AdminRoutes.filter(menuItem => menuItem);
      this.menuItemsInst = AdminRoutesInst.filter(menuItemInst => menuItemInst);
      this.menuItemsUser = AdminRoutesUser.filter(menuItemUser => menuItemUser);
      this.menuItemsBlog = AdminRoutesBlog.filter(menuItemBlog => menuItemBlog);
      this.menuItemsCurso = AdminRoutesCursos.filter(menuItemCurso => menuItemCurso);
      this.titsBlog = TitBlog.filter(titBlog => titBlog);
    }
        
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  userLogado(): boolean {

    this.token = this.localStorageUtils.obterTokenUsuario();
    this.user = this.localStorageUtils.obterUsuario();

    if (this.user) this.saudacao = 'Olá! ' + this.user.nomeUsuario;     

    return this.token !== null;
  }

  logOut()
  {
    this.localStorageUtils.limparDadosLocaisUsuario();
    this.router.navigate(['/home']);
  }

}


