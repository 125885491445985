import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html'
})
export class TopBarBlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
