import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { Login } from '../models/login';
import { Registro } from '../models/registro';
import { TrocarEmail } from './../models/trocarEmail';
import { Usuario } from './../models/usuario';
import { ResetarSenha } from '../models/resetaSenha';
import { AtualizaUsuario } from '../models/atualizaUsuario';
import { UsuarioInstituicao } from 'src/app/instituicao/models/usuario-instituicao'; 
import { RecuperaSenha } from '../models/recuperaSenha';
import { TrocarSenha } from '../models/trocarSenha';
import { TrocarTelefone } from './../models/TrocarTelefone';

import { BaseService } from '../../services/base.service';

import { CustomEncoder } from 'src/app/utils/customEncoder';
import { LocalStorageUtils } from 'src/app/utils/localStorage';
import { Usuarios } from 'src/app/dashboard/usuarios/models/Usuarios';

@Injectable()
export class AuthService extends BaseService {

  private authUrl: string = '/usuarios/';
  private manageUrl: string = '/manage/';
  private usuariosUrl: string = '/usuarios/';
  localStorageUtils = new LocalStorageUtils();
  constructor(private http: HttpClient, private _jwtHelper: JwtHelperService, private router: Router) { super(); }

  registrar(registro: Registro): Observable<any> {
    return this.http
      .post(this.UrlService + '/auth/registrar', registro)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  registrarinstituicao(registro: Registro): Observable<any> {
    return this.http
      .post(this.UrlService + '/auth/registrarinstituicao', registro)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  login(login: Login): Observable<Login> {
    return this.http
      .post(this.UrlService + '/auth/login', login)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  esqueceuasenha(recuperaSenha: RecuperaSenha): Observable<RecuperaSenha> {
    return this.http
      .post(this.UrlService + '/auth/esqueceuasenha', recuperaSenha)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  novasenha(resetarSenha: ResetarSenha): Observable<ResetarSenha> {
    return this.http
      .post(this.UrlService + '/auth/novasenha', resetarSenha)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  confirmarEmail(token: string, email: string): Observable<any> {
    let param = new HttpParams({ encoder: new CustomEncoder() });
    param = param.append('token', token);
    param = param.append('email', email);

    return this.http.get(this.UrlService + '/auth/confirmaremail', { params: param })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  confirmarTrocaEmail(token: string, newEmail: string, userId: string): Observable<any> {
    let param = new HttpParams({ encoder: new CustomEncoder() });
    param = param.append('token', token);
    param = param.append('newEmail', newEmail);
    param = param.append('userId', userId);

    return this.http.get(this.UrlService + '/auth/confirmartrocaemail', { params: param })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  trocarSenha(trocarSenha: TrocarSenha) {
    return this.http.post(this.UrlService + this.manageUrl + 'trocarsenha', trocarSenha)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  trocarEmail(trocarEmail: TrocarEmail) {
    return this.http.post(this.UrlService + this.manageUrl + 'trocaremail', trocarEmail)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  trocarTelefone(trocarTelefone: TrocarTelefone) {
    return this.http.put(this.UrlService + this.manageUrl + 'trocartelefone', trocarTelefone)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  atualizaUsuario(atualizaUsuario: AtualizaUsuario) {
    return this.http.put(this.UrlService + this.usuariosUrl + 'editarUsuario/', atualizaUsuario)
        .pipe(map(this.extractData), catchError(this.serviceError));
  }

  excluirUsuarioCadastro(id: string): Observable<AtualizaUsuario> {
    return this.http.delete(this.UrlService + this.usuariosUrl + 'excluir/' + id)
      .pipe(map(super.extractData),catchError(super.serviceError));
  }  

  obterTodosUsuariosAlunos(): Observable<UsuarioInstituicao[]> {
    return this.http
        .get<UsuarioInstituicao[]>(this.UrlService + this.usuariosUrl + 'usuario')
        .pipe(catchError(super.serviceError));
  } 

  obterUsuarioPorId(id: string): Observable<Usuario> {
    return this.http
      .get<Usuario>(this.UrlService + this.authUrl + id)
      .pipe(catchError(super.serviceError));
  }

  excluirUsuario(id: string): Observable<Usuario> {
    return this.http.delete(this.UrlService + this.authUrl + 'desativar/' + id)
      .pipe(map(super.extractData),catchError(super.serviceError));
  }  

  persistirUserApp(response: any) {
    this.localStorageUtils.salvarDadosLocaisUsuario(response);
  }

  public TokenExpired() {

    const token = localStorage.getItem('app.token');

    if (token !== null) {
      const expiredToken = this._jwtHelper.isTokenExpired(token);

      if (expiredToken === true) {
        this.localStorageUtils.limparDadosLocaisUsuario();
        this.router.navigate(['/home']);
      }
    }
  }
}