import { BolsasLayoutFiltrosComponent } from './navegacao/navegacao-bolsas/layout/bolsas-layout-filtros.component';
import { BolsasLayoutComponent } from './navegacao/navegacao-bolsas/layout/bolsas-layout.component';
import { HomepageComponent } from './home/homepage/homepage.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './navegacao/navegacao-dashboard/layout/admin-layout.component';
import { AuthLayoutComponent } from './navegacao/navegacao-conta/layout/auth-layout.component';
import { WebsiteLayoutComponent } from './navegacao/navegacao-home/layout/website-layout.component';
import { NaoEncontradoComponent } from './dashboard/erros/nao-encontrado/nao-encontrado.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '', component: AuthLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-conta/layout/auth-layout.module').then(m => m.AuthLayoutModule) }] },
  { path: '', component: AdminLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-dashboard/layout/admin-layout.module').then(m => m.AdminLayoutModule) }] },
  { path: '', component: WebsiteLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-home/layout/website-layout.module').then(m => m.WebsiteLayoutModule) }] },
  { path: '', component: BolsasLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-bolsas/layout/bolsas-layout.module').then(m => m.BolsasLayoutModule) }] },
  { path: '', component: BolsasLayoutFiltrosComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-bolsas/layout/bolsas-layout.module').then(m => m.BolsasLayoutModule) }] },
  { path: 'home', component: HomepageComponent },
  { path: '**', component: NaoEncontradoComponent },  
  { path: 'pagina-nao-encontrada', component: NaoEncontradoComponent },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
