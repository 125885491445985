import { SessionStorageUtils } from './../../../../utils/sessionStorage';
import { Component } from '@angular/core';
import { Usuarios } from '../sidebar/sidebar.component';
import { InstituicaoRoutes } from '../sidebar/sidebar.component';
import { InstituicaoRoutesUser } from '../sidebar/sidebar.component';
import { AdminRoutes } from '../sidebar/sidebar.component';
import { AdminRoutesBlog } from '../sidebar/sidebar.component';
import { AdminRoutesUser } from '../sidebar/sidebar.component';
import { AdminRoutesCursos } from '../sidebar/sidebar.component';
import { AdminRoutesInst } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalStorageUtils } from 'src/app/utils/localStorage';

declare interface RouteInfo {
  id?: any;
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const UsuariosMenu: RouteInfo[] = [
  { path: '/inscricao/minhas-inscricoes', title: 'Painel', icon: 'ni-settings-gear-65', class: '' },
  { path: '/perfil-de-usuario', title: 'Perfil', icon: 'ni ni-single-02 text-primary', class: '' }  
];

export const InstituicaoMenu: RouteInfo[] = [
  { path: '/dashboard-instituicao', title: 'Painel', icon: 'ni-settings-gear-65 text-primary', class: '' },
  { path: '/perfil-de-usuario', title: 'Perfil', icon: 'ni ni-single-02 text-primary', class: '' }
];

export const AdminMenu: RouteInfo[] = [
  { path: '/dashboard-admin', title: 'Painel', icon: 'ni-settings-gear-65 text-primary', class: '' },
  { path: '/perfil-de-usuario', title: 'Perfil', icon: 'ni ni-single-02 text-primary', class: '' }
];

export const Anonimous: RouteInfo[] = [];

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['../../layout/admin-layout.component.scss']
})
export class NavbarComponent {

  [x: string]: any;

  public listTitles: any[];
  public listTitlesInst: any[];
  public listTitlesAdmin: any[];
  public listTitlesBlog: any[];
  public location: Location;

  token: string = "";
  user: any;
  localStorageUtils = new LocalStorageUtils();
  sessionStorageUtils = new SessionStorageUtils();

  constructor(location: Location, private router: Router) {
    this.location = location;
  }

  ngOnInit() {
    this.listTitles = Usuarios.filter(listTitle => listTitle),
    this.listTitlesUser = InstituicaoRoutesUser.filter(listTitle => listTitle),    
    this.listTitlesInst = InstituicaoRoutes.filter(listTitle => listTitle),
    this.listTitlesAdmin = AdminRoutes.filter(listTitle => listTitle);
    this.listTitlesAdminBlog = AdminRoutesBlog.filter(listTitle => listTitle);
    this.listTitlesAdminInst = AdminRoutesInst.filter(listTitle => listTitle);
    this.listTitlesAdminUser = AdminRoutesUser.filter(listTitle => listTitle);
    this.listTitlesAdminCursos = AdminRoutesCursos.filter(listTitle => listTitle);

    this.user = this.localStorageUtils.obterUsuario();

    if (this.user != null || this.user != undefined) {
      let claim = this.user.claims[0];

      if (claim.type === 'Instituicao' && claim.value === 'Add,Edit,Update,Delete,Visualize') {
        this.menuItems = InstituicaoMenu.filter(menuItem => menuItem);
      }


      if (claim.type === 'Usuario' && claim.value === 'Add,Delete,Visualize') {
        this.menuItems = UsuariosMenu.filter(menuItem => menuItem);
      }

      if (claim.type === 'WiziSuperAdmin' && claim.value === 'Add,Edit,Update,Delete,Visualize') {
        this.menuItems = AdminMenu.filter(menuItem => menuItem);
      }
    }

  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesUser.length; item++) {
      if (this.listTitlesUser[item].path === titlee) {
        return this.listTitlesUser[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesInst.length; item++) {
      if (this.listTitlesInst[item].path === titlee) {
        return this.listTitlesInst[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesAdmin.length; item++) {
      if (this.listTitlesAdmin[item].path === titlee) {
        return this.listTitlesAdmin[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesAdminBlog.length; item++) {
      if (this.listTitlesAdminBlog[item].path === titlee) {
        return this.listTitlesAdminBlog[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesAdminInst.length; item++) {
      if (this.listTitlesAdminInst[item].path === titlee) {
        return this.listTitlesAdminInst[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesAdminCursos.length; item++) {
      if (this.listTitlesAdminCursos[item].path === titlee) {
        return this.listTitlesAdminCursos[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesAdminUser.length; item++) {
      if (this.listTitlesAdminUser[item].path === titlee) {
        return this.listTitlesAdminUser[item].title;
      }
    }
    return 'Dashboard';
  }

  userLogado(): boolean {

    this.token = this.localStorageUtils.obterTokenUsuario();
    this.user = this.localStorageUtils.obterUsuario();

    if (this.user)
      this.saudacao = 'Olá! ' + this.user.nomeUsuario;

    return this.token !== null;
  }

  logOut() {
    this.localStorageUtils.limparDadosLocaisUsuario();
    this.sessionStorageUtils.limparSessionStorage();
    this.router.navigate(['/home']);
  }

}
