import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    MatAutocompleteModule,
    
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ],  
   exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    
  ] 
})
export class ComponentsModule { }
