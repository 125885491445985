import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CepConsulta, Endereco } from "../models/endereco";
import { Instituicao } from "../models/Instituicao";
import { Registro } from "src/app/conta/models/Registro";
import { UsuarioInstituicao } from "../models/usuario-instituicao";
import { Estatisticas } from './../../dashboard/admin/home-admin/models/estatisticas';
import { BaseService } from "src/app/services/base.service";

@Injectable()
export class InstituicaoService extends BaseService {
    private instituicaoDashboard: string = '/dashboard/instituicao/';
    private instituicaoUrl: string = '/cadastro/instituicao/';
    private instituicaoEnderecoUrl: string = '/cadastro/instituicao/endereco/';
    private homeUrl: string = '/home/';
    private usuariosUrl: string = '/usuarios/';

    constructor(private http: HttpClient) { super() }

    obterTodos(): Observable<Instituicao[]> {
        return this.http
            .get<Instituicao[]>(this.UrlService + this.instituicaoUrl)
            .pipe(catchError(super.serviceError));
    }

    obterPorUsuario(): Observable<Instituicao> {
        return this.http
            .get<Instituicao>(this.UrlService + this.instituicaoUrl + 'user')
            .pipe(catchError(super.serviceError));
    }

    obterPorId(id: string): Observable<Instituicao> {
        return this.http
            .get<Instituicao>(this.UrlService + this.instituicaoUrl + id)
            .pipe(catchError(super.serviceError));
    }

    novaInstituicao(instituicao: Instituicao): Observable<Instituicao> {
        return this.http
            .post(this.UrlService + this.instituicaoUrl, instituicao)
            .pipe(map(super.extractData), catchError(super.serviceError));
    }

    novaInstituicaoAdmin(instituicao: Instituicao): Observable<Instituicao> {
        return this.http
            .post(this.UrlService + this.instituicaoUrl + 'admin', instituicao)
            .pipe(map(super.extractData), catchError(super.serviceError));
    }

    editarInstituicao(instituicao: Instituicao): Observable<Instituicao> {
        return this.http
            .put(this.UrlService + this.instituicaoUrl + instituicao.id, instituicao)
            .pipe(map(super.extractData),catchError(super.serviceError));
    }

    editarInstituicaoAdmin(instituicao: Instituicao): Observable<Instituicao> {
        return this.http
            .put(this.UrlService + this.instituicaoUrl + 'admin/' + instituicao.id, instituicao)
            .pipe(map(super.extractData),catchError(super.serviceError));
    }

    excluirInstituicao(id: string): Observable<Instituicao> {
        return this.http
            .delete(this.UrlService + this.instituicaoUrl + id)
            .pipe(map(super.extractData),catchError(super.serviceError));
    }


    atualizarEndereco(endereco: Endereco): Observable<Endereco> {
        return this.http
            .put(this.UrlService + this.instituicaoEnderecoUrl + endereco.id, endereco)
            .pipe(map(super.extractData),catchError(super.serviceError));
    }

    consultarCep(cep: string): Observable<CepConsulta> {
        return this.http.get<CepConsulta>('https://viacep.com.br/ws/' + cep + '/json/');
    }
    
    obterEstatisticas(id: string): Observable<Estatisticas> {
        return this.http
            .get<Estatisticas>(this.UrlService + this.instituicaoDashboard + 'estatisticas/' + id)
            .pipe(catchError(super.serviceError));
    }

    obterEstatisticasInstituicao(): Observable<Estatisticas> {
        return this.http
            .get<Estatisticas>(this.UrlService + this.instituicaoDashboard + 'estatisticas')
            .pipe(catchError(super.serviceError));
    }

    obterTodosEnderecos(id: string): Observable<Endereco[]> {
        return this.http
            .get<Endereco[]>(this.UrlService + this.homeUrl + id)
            .pipe(catchError(super.serviceError));
    }

    obterCidadesEnderecos(): Observable<Endereco[]> {
        return this.http
            .get<Endereco[]>(this.UrlService + this.homeUrl + 'home/')
            .pipe(catchError(super.serviceError));
    }

    obterTodosUsuarios(): Observable<UsuarioInstituicao[]> {
        return this.http
            .get<UsuarioInstituicao[]>(this.UrlService + this.usuariosUrl + 'instituicao')
            .pipe(catchError(super.serviceError));
    }    

    novoUsuarioInstituicao(UsuarioInstituicao: Registro): Observable<any> {
        return this.http
            .post(this.UrlService + '/auth/registrarusuarioinstituicao', UsuarioInstituicao)
            .pipe(map(this.extractData), catchError(this.serviceError));
    }  
        
    obterUsuarioPorId(id: string): Observable<UsuarioInstituicao> {
        return this.http
            .get<UsuarioInstituicao>(this.UrlService + this.usuariosUrl + id)
            .pipe(catchError(super.serviceError));
    }
    
    editarUsuarioInst(usuarioInstituicao: UsuarioInstituicao): Observable<UsuarioInstituicao> {
        return this.http
        .put(this.UrlService + this.usuariosUrl + 'editarUsuario/' + usuarioInstituicao.id, usuarioInstituicao)
            .pipe(map(super.extractData),catchError(super.serviceError));
    }
    

    excluirUsuarioInst(id: string): Observable<UsuarioInstituicao> {
        return this.http
            .delete(this.UrlService + this.usuariosUrl + 'excluir/' + id)
            .pipe(map(super.extractData),catchError(super.serviceError));
    }
    
}