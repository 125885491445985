import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Categoria } from 'src/app/cursos/models/categoria';
import { Cursos } from 'src/app/cursos/models/cursos';
import { Modalidade } from 'src/app/cursos/models/modalidade';
import { Turno } from 'src/app/cursos/models/turno';

import { CursosService } from 'src/app/cursos/services/cursos.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['../../layout/bolsas-layout.component.scss']
})
@Injectable({ providedIn: 'any' })
export class SideBarComponent implements OnInit {

  @Output() groupFilters: EventEmitter<any> = new EventEmitter<any>();
  formulario: FormGroup;
  cursos: Cursos[];
  turnos: Turno;
  modalidades: Modalidade;  
  categorias: Categoria;
  errorMessage: string;  



  constructor(
    private fb: FormBuilder,
    private cursosService: CursosService,
    private modalService: NgbModal,    
  ) { }

  @ViewChild('divClick') divClick: ElementRef;

  ngOnInit(): void {

    setTimeout(() => {
      this.divClick.nativeElement.click();
    }, 1700);

    this.buildForm();

    this.cursosService.obterTodos()
      .subscribe(cursos => this.cursos = cursos, error => this.errorMessage);

    this.cursosService.obterCategorias()
      .subscribe(categorias => this.categorias = categorias);

    this.cursosService.obterModalidades()
      .subscribe(modalidades => this.modalidades = modalidades);

    this.cursosService.obterTurnos()
      .subscribe(turnos => this.turnos = turnos);

  }

  buildForm(): void {
    this.formulario = this.fb.group({
      nome: [],
      nomeInstituicao: [],
      cidadeInstituicao: [],
      modalidadeId: [],
      categoriaId: [],
      turnoId: []
    });
  }

  search(filters: any): void {

    let filterLenght = Object.keys(filters).length;
    let isEmpty = Object.values(filters).every(x => (x === null || x === ''));

    if (isEmpty) {
      let newFilters = [''];
      Object.keys(newFilters).forEach(key => newFilters[key] === '' ? delete newFilters[key] : key);
      this.groupFilters.emit(newFilters);
    }

    if (!isEmpty && filterLenght > 0) {
      //this.form.reset();
      Object.keys(filters).forEach(key => filters[key] === '' ? delete filters[key] : key);
      this.groupFilters.emit(filters);
    }

    if (filterLenght == 0) {
      const newFilters = [''];
      Object.keys(newFilters).forEach(key => newFilters[key] === '' ? delete newFilters[key] : key);
      this.groupFilters.emit(newFilters);
      //this.form.reset();
    }
  }

  abrirModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  resetCategoria() {
    this.formulario.controls['categoriaId'].reset();
  }

  resetModalidade() {
    this.formulario.controls['modalidadeId'].reset();
  }

  resetTurno() {
    this.formulario.controls['turnoId'].reset();
  }

  resetFiltro() {
    this.formulario.reset();
  }

}
